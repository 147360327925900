import React from 'react'
import CosiSlider from '../utils/cosiSlider'
import Agenda from '../components/Agenda'
import Moment from 'moment'
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
moment.locale("es")
import styled from 'styled-components'
import ReactStateAnimation from 'react-state-animation'
import update from 'immutability-helper'
import { withApollo, Query } from "react-apollo"
import gql from "graphql-tag"

const BotonAgenda = styled.div`
    cursor:${props=>props.disabled?"auto":"pointer"};
    color: ${props=>props.disabled?"#fff":"#c7c7c7"};
    font-size: 32px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    box-sizing: border-box;
`

export default class AgendaWithSlider extends React.Component {

    today = moment()

    constructor(props) {
        super(props)
        this.animate = new ReactStateAnimation(this)
        this.state = {
            first: null,
            last: null,
            actual: {
                date: props.date.clone(),
                position: this.today.isSame(props.date, "day") ? "start2" : "start"
            },
            left: 0,
            right: 0
        }
        console.log("constructing agendawithslider")
    }

    componentDidMount() {
        console.log("agendawithslider did mount")
    }

    right = async () => {
        console.log("starting right animation")
        console.log("stoping previous  animation")
        this.setState({
            width: this.center.offsetWidth,
            right: 0,
            left: 0
        }, async () => {
            console.log("starting right callback")
            if (this.rightAgendaRef.props.position == "initial")
                this.props.onChange(this.rightAgendaRef.props.date.clone())
            else
                this.props.onChange(this.rightAgendaRef.firstDay.clone())
            console.log("called right onchange awaiting")
            await this.animate.linearIn('left', -this.center.offsetWidth, 400)
            console.log("setting right state final")
            this.setState({
                actual: this.state.last,
                left: 0,
                first: this.state.actual,
                last: {
                    date: this.rightAgendaRef.lastDay.clone().add(1, "days"),
                    position: "start"
                },
                width: undefined
            }, () => {
                console.log("right state final setted")
                if (this.actualAgendaRef.props.position == "initial")
                    this.props.onChange(this.actualAgendaRef.props.date.clone())
                else
                    this.props.onChange(this.actualAgendaRef.firstDay.clone())
            })
        })
    }

    left = async () => {
        console.log("starting left animation")
        console.log("stoping previous  animation")
        this.setState({
            width: this.center.offsetWidth,
            right: 0,
            left: 0
        }, async () => {
            console.log("starting left callback")
            if (this.leftAgendaRef.props.position == "initial")
                this.props.onChange(this.leftAgendaRef.props.date.clone())
            else
                this.props.onChange(this.leftAgendaRef.firstDay.clone())
            console.log("called left onchange awaiting")
            await this.animate.linearIn('right', -this.center.offsetWidth, 400)
            console.log("setting left state final")
            this.setState({
                actual: this.state.first,
                right: 0,
                first: {
                    date: this.leftAgendaRef.firstDay.clone().subtract(1, "days"),
                    position: "end"
                },
                last: this.state.actual,
                width: undefined
            }, () => {
                console.log(this.actualAgendaRef)
                if (this.actualAgendaRef.props.position == "initial")
                    this.props.onChange(this.actualAgendaRef.props.date.clone())
                else
                    this.props.onChange(this.actualAgendaRef.firstDay.clone())
            })
        })

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.width == undefined) {
            if (this.state.actual.position == "start") {
                if (!this.actualAgendaRef.firstDay.isSame(nextProps.date, "day")) {
                    console.log("se debe actualizar start")
                    this.setState({
                        first: null,
                        last: null,
                        actual: {
                            date: nextProps.date.clone(),
                            position: this.today.isSame(nextProps.date, "day") ? "start2" : "start2"
                        }
                    })
                }
            }
            if (this.state.actual.position == "end") {
                if (!this.actualAgendaRef.firstDay.isSame(nextProps.date, "day")) {
                    console.log("se debe actualizar end", this.actualAgendaRef.firstDay, nextProps.date)
                    this.setState({
                        first: null,
                        last: null,
                        actual: {
                            date: nextProps.date.clone(),
                            position: this.today.isSame(nextProps.date, "day") ? "start2" : "start2"
                        }
                    })
                }
            }
            if (this.state.actual.position == "initial" || this.state.actual.position == "start2") {
                if (!this.state.actual.date.isSame(nextProps.date, "day")) {
                    console.log("se debe actualizar initial", this.actualAgendaRef.lastDay, nextProps.date)
                    this.setState({
                        first: null,
                        last: null,
                        actual: {
                            date: nextProps.date.clone(),
                            position: this.today.isSame(nextProps.date, "day") ? "start2" : "start2"
                        }
                    })
                }
            }
        }
    }


    firstLastCallback = (first, last) => {

        if (!this.state.first && !this.state.last) {
            console.log("first callback", first, last)
            this.setState({
                first: {
                    date: first.clone().subtract(1, "days"),
                    position: "end"
                },
                last: {
                    date: last.clone().add(1, "days"),
                    position: "start"
                }
            })
        }
    }

    openCita = (cita, preview)=>{
        this.actualAgendaRef.editCita(cita, preview)
    }

    refresh = () => {
        this.leftAgendaRef.updateCitas()
        this.actualAgendaRef.updateCitas()
        this.rightAgendaRef.updateCitas()
    }

    render() {
        const disableLeftArrow= this.actualAgendaRef && this.props.hideCitas && this.actualAgendaRef.firstDay.isSameOrBefore(this.props.today, "day")
        console.log("disabled", disableLeftArrow, this.actualAgendaRef && this.actualAgendaRef.firstDay, this.props.today)
        const botonLeft = <BotonAgenda style={{ textAlign:"left", paddingLeft:20 }} onClick={this.left} ><i className="fa fa-chevron-left"></i></BotonAgenda>
        const botonRight = <BotonAgenda style={{ textAlign:"right", paddingRight:20 }} onClick={this.right} ><i className="fa fa-chevron-right"></i></BotonAgenda>

        return <Query fetchPolicy={"cache-and-network"} query={gql`query suscription($businesses_id: Int){
            suscription(businesses_id: $businesses_id){
                id
            }
        }`} variables={{ businesses_id: this.props.business.id }} >
            {({ loading, error, data: { suscription } = {}, refetch }) => {
            return <div style={{ ...this.props.containerStyle, position:"relative", display: "flex", flexDirection: "row", overflow: "hidden", width: this.state.width, justifyContent: this.state.right != 0 ? "flex-end" : "flex-start" }} >
                {this.state.first && <div key={this.state.first.date.format()} ref={ref => { this.leftRef = ref }} style={{ minWidth: this.props.width, ...this.state.right >= 0 ? { position: "absolute", visibility: "hidden" } : { position: "relative", visibility: "visible" } }} >
                    <Agenda active={!!suscription} callbackOnSaveCita={this.props.callbackOnSaveCita} today={this.props.today} botonRight={botonRight} botonLeft={botonLeft} width={this.props.width} ref={ref => { this.leftAgendaRef = ref }} {...this.state.first} hideCitas={this.props.hideCitas} user={this.props.user} service={this.props.service} business={this.props.business} callbackOnUpdateCita={this.props.callbackOnUpdateCita} /></div>}
                <div id={"agenda"} key={this.state.actual.date.format()} style={{ marginLeft: this.state.left, marginRight: this.state.right, minWidth: this.props.width }} ref={ref => { this.center = ref }} >
                    <Agenda active={!!suscription} callbackOnSaveCita={this.props.callbackOnSaveCita} today={this.props.today} numberDaysCalculated={this.props.numberDaysCalculated} botonRight={botonRight} botonLeft={!disableLeftArrow && botonLeft} width={this.props.width} ref={ref => { this.actualAgendaRef = ref }} firstLastCallback={this.firstLastCallback}
                        position={this.state.actual.position}
                        hideCitas={this.props.hideCitas} user={this.props.user} date={this.state.actual.date} service={this.props.service} business={this.props.business} callbackOnUpdateCita={this.props.callbackOnUpdateCita} /></div>
                {this.state.last && <div key={this.state.last.date.format()} ref={ref => { this.rightRef = ref }} style={{ minWidth: this.props.width, ...this.state.left >= 0 ? { position: "absolute", visibility: "hidden" } : { position: "relative", visibility: "visible" } }} >
                    <Agenda active={!!suscription} callbackOnSaveCita={this.props.callbackOnSaveCita} today={this.props.today} botonRight={botonRight} botonLeft={botonLeft} width={this.props.width} ref={ref => { this.rightAgendaRef = ref }} {...this.state.last} hideCitas={this.props.hideCitas} user={this.props.user} service={this.props.service} business={this.props.business} callbackOnUpdateCita={this.props.callbackOnUpdateCita} /></div>}
            </div>
            }}
            </Query>
    }
}
