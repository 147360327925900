import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import ReactStateAnimation from 'react-state-animation'


export default class CosiSlider extends React.Component {

    constructor(props) {
        console.log("constructing cosi slider")
        super(props)
        this.update = this.update.bind(this)
        this.up = this.up.bind(this)
        this.down = this.down.bind(this)
        this.right = this.right.bind(this)
        this.left = this.left.bind(this)
        this.setValues = this.setValues.bind(this)
        this.animate = new ReactStateAnimation(this)
        this.state = {
            values: props.initValues,
            components: [],
            buffer: null,
            flexDirection: props.initAxis,
            overflow: "hidden"
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log("will receive new props", nextProps, this.props)
        this.update()
    }


    componentDidMount() {
        console.log("cosislider did mount")
    }

    update() {

        const updatedElements = this.props.update(this.state.values, (vals) => this.setValues(vals))

        this.setState({
            buffer: updatedElements,
            components: updatedElements
        })
    }

    componentWillMount() {
        this.update()
    }

    up() {
        this.animate.stop()

        this.direction = "up"
        const newElement = this.props.up(this.state.values, (vals) => this.setValues(vals))
        const bufferResult = [newElement]
        for (let i = 0; i < this.props.slidesToShow - bufferResult.length; i++)
            bufferResult.push(this.state.components[i])
        this.setState({
            overflow: "hidden",
            buffer: newElement,
            flexDirection: "column",
            bufferResult: bufferResult,
            width: this.components.offsetWidth
        }, () => {
            console.log(this.bufferResult.offsetHeight)
            this.diffHeight = this.bufferResult.offsetHeight - this.state.height
            this.state.components.unshift(newElement)
            console.log(this.state.components)
            this.setState({
                top: -this.buffer.offsetHeight,
                components: this.state.components,
                bufferResult: null,
                height: this.bufferResult.offsetHeight
            }, async () => {
                console.log("stoping", this.animate)
                await this.animate.quadIn('top', 0, 200)
                this.state.components.splice(this.props.slidesToShow)
                this.state.values.splice(this.props.slidesToShow)
                this.setState({
                    overflow: "hidden",
                    components: this.state.components,
                })
            })
        })

    }

    down() {
        this.animate.stop()
        this.direction = "down"
        const newElement = this.props.down(this.state.values, (vals) => this.setValues(vals))
        const bufferResult = [newElement]
        let i = this.state.components.length - 1
        while (bufferResult.length < this.props.slidesToShow) {
            bufferResult.unshift(this.state.components[i])
            i--
        }
        this.state.components.push(newElement)
        this.setState({
            height: this.components.offsetHeight,
            width: this.components.offsetWidth,
            overflow: "hidden",
            top: 0,
            flexDirection: "column",
            buffer: this.state.components[0],
            components: this.state.components,
            bufferResult: bufferResult
        }, async () => {
            this.diffHeight = this.bufferResult.offsetHeight - this.state.height
            this.offsetHeight = -this.buffer.offsetHeight
            console.log("this.offsetHeight: ")
            console.log(this.offsetHeight)
            console.log("this.diffHeight: ")
            console.log(this.diffHeight)
            this.setState({
                height: this.bufferResult.offsetHeight,
                bufferResult: null
            })
            await this.animate.quadIn('top', this.offsetHeight, 200)
            this.state.components.splice(0, this.state.components.length - this.props.slidesToShow)
            this.state.values.splice(0, this.state.values.length - this.props.slidesToShow)
            this.setState({
                overflow: "hidden",
                top: 0,
                components: this.state.components,
                values: this.state.values
            })
        })
    }

    right() {
        const offsetWidth = this.buffer.offsetWidth
        this.direction = "right"
        const newElement = this.props.right(this.state.values, (vals) => this.setValues(vals), this.state.components)
        const bufferResult = [newElement]
        let i = this.state.components.length - 1
        while (bufferResult.length < this.props.slidesToShow) {
            bufferResult.unshift(this.state.components[i])
            i--
        }
        this.state.components.push(newElement)
        this.setState({
            overflow: "hidden",
            left: 0,
            flexDirection: "row",
            components: this.state.components,
            bufferResult: bufferResult,
            height: this.buffer.offsetHeight,
            width: this.buffer.offsetWidth
        }, async () => {
            console.log("this.offsetWidth:")
            console.log(-offsetWidth)
            await this.animate.linearIn('left', -offsetWidth, this.props.duration)
            this.state.components.splice(0, this.state.components.length - this.props.slidesToShow)
            this.state.values.splice(0, this.state.values.length - this.props.slidesToShow)
            this.setState({
                left: 0,
                overflow: "visible",
                components: this.state.components,
                values: this.state.values,
                width: undefined,
                height: undefined
            })
        })
    }

    left() {
        const offsetWidth = this.buffer.offsetWidth
        this.direction = "left"
        const newElement = this.props.left(this.state.values, (vals) => this.setValues(vals), this.state.components)
        const bufferResult = [newElement]
        for (let i = 0; i < this.props.slidesToShow - bufferResult.length; i++)
            bufferResult.push(this.state.components[i])
        this.state.components.unshift(newElement)
        this.setState({
            overflow: "hidden",
            flexDirection: "row",
            bufferResult: bufferResult,
            left: -offsetWidth,
            components: this.state.components,
            offsetWidth: offsetWidth,
            height: this.buffer.offsetHeight,
            width: this.buffer.offsetWidth
        }, async () => {
            await this.animate.linearIn('left', 0, this.props.duration)
            this.state.components.splice(this.props.slidesToShow)
            this.state.values.splice(this.props.slidesToShow)
            this.setState({
                overflow: "visible",
                components: this.state.components,
                values: this.state.values,
                offsetWidth: null,
                width: undefined,
                height: undefined
            })
        })
    }

    setValues(newVals, callback) {
        console.log("setting new values cosi slider")
        console.log(this.state.values)
        console.log(newVals)
        this.setState({
            values: newVals
        }, () => { if (callback) callback() })
    }

    componentWillUpdate() {

    }

    componentDidUpdate() {

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.components !== nextState.components) {

            return true
        }
        else if (nextProps !== this.state.props) {

            return true
        }
        else
            return false
    }

    render() {
        this.refs = []
        return <div style={{ overflow: this.state.overflow, width: this.state.width, height: this.state.height, position: "relative" }} >
            <div ref={ref => { this.components = ref }} style={{ position: "relative", display: "flex", flexDirection: this.state.flexDirection, top: this.state.top, left: this.state.left }}>
                {this.state.components.map((comp, index) => <div key={comp.key ? comp.key : index} style={{ minWidth: this.state.offsetWidth }} >{comp}</div>)}
            </div>
            <div ref={ref => { this.buffer = ref }} style={{ display: "flex", flexDirection: this.state.flexDirection, position: "absolute", top: 0, visibility: "hidden" }}>
                {this.state.buffer}
            </div>
            <div ref={ref => { this.bufferResult = ref }} style={{ display: "flex", flexDirection: this.state.flexDirection, position: "absolute", top: 0, visibility: "hidden" }}>
                {this.state.bufferResult}
            </div>
        </div>
    }

}
